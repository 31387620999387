import Head from 'next/head';
import Script from 'next/script';
import { GoogleAnalytics } from '@next/third-parties/google'
import '../public/scss/style.scss';

function YatirimNedir({ Component, pageProps }) {
  return (
    <>
      <Head>
        <link rel="shortcut icon" href="/favicon.ico" />
        <meta name="google-site-verification" content="KnDy-mrjrdsRmls81qzUuKB0H1dMC3BPgQQhtEW6Wd0" />
      </Head>
      <Component {...pageProps} />
      <GoogleAnalytics gaId="G-T3PY3KJGF1" />
    </>
  )
}

export default YatirimNedir;
